import styled, {
  css,
  keyframes,
  FlattenSimpleInterpolation,
} from 'styled-components';

export const FormHeader = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: space-between;
  padding: 2rem 4rem;
  width: 100%;

  && h1 {
    color: #fff;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: normal;
    margin: 0;
  }

  a {
    svg {
      fill: white;
      height: 3.25rem;
      transition: all 0.5s ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
      svg {
        fill: #27bd2f;
        stroke: #2b2b2b;
        transition: all 0.5s ease-in-out;
      }
    }
  }
`;

export const FormInfo = styled.div`
  align-items: center;
  color: #b68787;
  display: flex;
  font-weight: normal;
  padding: 1rem 4rem;
  width: 100%;

  i {
    background: #ff7b7b;
    border-radius: 50%;
    color: black;
    display: inline-block;
    flex-shrink: 0;
    font-size: 1rem;
    height: 1.5rem;
    line-height: 1.5;
    font-style: normal;
    font-weight: normal;
    margin-right: 1rem;
    text-align: center;
    width: 1.5rem;
  }

  &.help,
  &.success,
  &.error {
    align-items: flex-start;

    i {
      margin-top: 0.5rem;
    }
  }

  &.help {
    color: #bff0ff;

    i {
      background: #bff0ff;
    }
  }

  &.success {
    color: #2fff2f;

    i {
      background: #2fff2f;
    }
  }

  &.error {
    color: #ff7b7b;

    i {
      background: #ff7b7b;
      line-height: 1.2;
    }
  }
`;

export const FormInput = styled.input`
  background: none;
  border: none;
  border-top: solid 1px #222;
  color: white;
  font-family: 'Sofia Pro Light', var(--font-family), sans-serif;
  font-size: 1rem;
  padding: 1rem 4rem;
  transition: all 0.5s ease-in-out;
  width: 100%;

  &::placeholder {
    color: #999;
  }

  &:hover,
  &:focus,
  &:active {
    background: #444;
    color: white;
    outline: none;
    transition: all 1s ease-in-out;

    &::placeholder {
      color: #cdc;
    }
  }

  &:focus {
    &::placeholder {
      color: #555;
    }
  }
`;

export const FormSubmit = styled.button`
  background: none;
  border: none;
  border-top: solid 1px #1b1b1b;
  color: white;
  cursor: pointer;
  font-family: 'Sofia Pro Light', var(--font-family), sans-serif;
  font-size: 1rem;
  padding: 1rem 4rem;
  transition: all 0.5s ease-in-out;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    background: #2fff2f;
    border-top: solid 1px transparent;
    color: black;
    transition: all 1s ease-in-out;
  }
`;

export const FormBody = styled.div`
  background: #2b2b2b;
  border-top: solid 1px white;
  box-shadow: 1px 0.32rem 0.62rem rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;

  .item {
    display: flex;
    height: auto;
    width: 100%;
  }

  &.hasError ${FormInput} {
    color: #9a8686;
  }
`;

const changeSizeAnim = keyframes`
  from {
    box-shadow: 0 0 0 0 #32c332,
      inset 0 0 0 1px #32c332,
      inset 0 0 0 1rem #FFFFFF,
      inset 0 0 0 1rem #32c332;
  }

  to {
    box-shadow: 0 0 0 1px #32c332,
      inset 0 0 0 1px #32c332,
      inset 0 0 0 1rem #FFFFFF,
      inset 0 0 0 1rem #32c332;
  }
`;

const selectCheckboxAnim = keyframes`
  0% {
    box-shadow: 0 0 0 0 #88d4a3,
      inset 0 0 0 0.125rem #FFFFFF,
      inset 0 0 0 0.1875rem #32c332,
      inset 0 0 0 1rem #FFFFFF,
      inset 0 0 0 1rem #32c332;
  }

  100% {
    box-shadow: 0 0 1.25rem 0.5rem #eeddee,
      inset 0 0 0 0 white,
      inset 0 0 0 1px #88d4a3,
      inset 0 0 0 0 #FFFFFF,
      inset 0 0 0 1rem #32c332;
  }
`;

export const baseInputOptionStyles = css`
  display: inline-block;
  height: 1.2rem;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 1.2rem;

  & + label {
    display: inline-block;
    cursor: pointer;
    line-height: 1rem;
    margin: 0;
    padding: 0 0 0 1rem;
    position: relative;
    transition: all 0.5s ease-in-out;
    white-space: nowrap;

    span {
      margin-right: 1.5rem;
    }

    &::before,
    &::after {
      border-radius: 50%;
      color: #f5f5f5;
      content: '';
      height: 1rem;
      margin-left: 1rem;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      transition: all 0.5s ease-in-out;
      width: 1rem;
    }

    &::before {
      background-color: #ffffff;
      border-radius: 0;
      box-shadow: inset 0 0 0 1px #729a72, inset 0 0 0 1rem #ffffff,
        inset 0 0 0 1rem #2fff2f;
      color: #ffffff;
      font: normal normal bolder 0.6rem/1 'themify', sans-serif;
      line-height: 1rem;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &:hover,
    &:focus,
    &:active {
      color: #2ca92c;
      font-weight: bolder;
      outline: none;
      transition: all 0.3s ease;

      &::before {
        animation-direction: alternate;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-name: ${changeSizeAnim};
        box-shadow: inset 0 0 0 1px #2fff2f, inset 0 0 0 1rem #ffffff,
          inset 0 0 0 1rem #2fff2f;
        color: #ffffff;
      }
    }
  }

  &:focus,
  &:hover,
  &:checked {
    & + label {
      color: #2ca92c;
    }

    & + label::before {
      animation-direction: alternate;
      animation-duration: 0.3s;
      animation-iteration-count: 1;
      animation-name: ${selectCheckboxAnim};
      border: solid 1px #32c332;
      box-shadow: inset 0 0 0 1px #90d488, inset 0 0 0 0 #ffffff,
        inset 0 0 1px 0.125rem #32c332;
    }
  }

  &:checked {
    & + label::before {
      content: '\\e64c'; /* Shows ti-check*/
      background-color: #32c332;
      border: solid 1px rgba(63, 134, 79, 0.5);
      box-shadow: inset 0 0 0 1px #90d488, inset 0 0 0 0 #ffffff,
        inset 0 0 0 1rem #32c332;
      color: #ffffff;
    }
  }
`;

export const CheckboxBase = styled.input.attrs({ type: 'checkbox' })`
  ${baseInputOptionStyles}
`;
export const OptionInputWrapper = styled.div`
  padding: 0;
  position: relative;
  margin: 0;
`;

export const FormFooter = styled.div`
  align-items: center;
  color: #2b2b2b;
  display: flex;
  font-size: 0.85rem;
  font-weight: normal;
  justify-content: space-between;
  margin-top: 1rem;
  transition: all 0.5s ease-in-out;

  a {
    color: #7a7a7a;

    &:hover,
    &:focus,
    &:active {
      color: #27bd2f;
      font-weight: bold;
      transition: all 0.5s ease-in-out;
    }
  }

  label {
    color: black;

    input {
      margin-left: 1rem;
      cursor: pointer;
    }
  }
`;

export const FormContainer = styled.form`
  height: auto;
  margin: 0 auto;
  padding: 0;
  width: 25%;

  @media all and (min-width: ${(props): string =>
      props.theme.breakpoints.large}) {
    background: yellows;
  }
`;

const LoginPageWrapper = styled.div`
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    color: #555;
    font-family: 'Lora', 'Libre Baskerville', serif;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: bold;
  }
`;

const curveStyles = (): FlattenSimpleInterpolation => {
  let styles = '';

  for (let i = 1; i <= 4; i += 1) {
    styles += `
      path:nth-child(${i}) {
        animation: curveAnimation 6s linear ${i * 0.2}s infinite;
      }
    `;
  }

  return css`
    ${styles}
  `;
};

export const ProgressWrapper = styled.div`
  background: #2b2b2b;
  height: 10rem;
  position: relative;
  overflow: hidden;
  width: 100%;

  ${FormInfo} {
    align-items: flex-start;
    background: none;
    border-top: none;
    color: #27bd2f;

    i {
      background: #27bd2f;
      margin-top: 0.1rem;
      line-height: 1;
    }
  }

  .loader {
    bottom: -0.1rem;
    height: 10rem;
    position: absolute;
    right: -4rem;
    transform: rotate(-5deg);
    width: 100%;

    svg {
      display: block;
      height: 18rem;
      margin: 0 auto;
      transform: rotate(-15deg);
      width: 100%;

      g {
        fill: none;
      }

      circle {
        fill: #efe;
      }
    }
  }

  .paths {
    stroke-opacity: 0;
    stroke-width: 1px;

    path {
      transform-origin: 50% 10%;
      stroke: #27bd2f;
    }

    ${curveStyles()};
  }

  @keyframes curveAnimation {
    0% {
      transform: none;
      stroke-opacity: 0;
    }

    50% {
      transform: rotateX(620deg) scaleY(0.2);
      stroke-opacity: 0.4;
    }

    70% {
      transform: skewX(-5deg);
    }

    100% {
      transform: none;
      stroke-opacity: 0;
    }
  }
`;

export default LoginPageWrapper;
