import styled, { keyframes } from 'styled-components';

export const ToggleButtonWrapper = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  display: none;
  height: 2.5rem;
  margin: 0;
  padding: 0;
  position: relative;
  transition: 0.5s ease-in-out;
  width: 2.5rem;
  z-index: 2;

  span {
    background-color: #035214;
    border-radius: 1rem;
    display: block;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
      height: 0.125rem;
      margin: 0.1875rem auto;
      position: relative;
      width: 1.5rem;

      &:nth-child(1) {
        transition-delay: 0.5s;
      }

      &:nth-child(2) {
        transition-delay: 0.625s;
      }

      &:nth-child(3) {
        transition-delay: 0.75s;
      }
    }
  }

  .cross {
    height: 100%;
    position: absolute;
    transform: rotate(45deg);
    width: 100%;

    span {
      &:nth-child(1) {
        height: 0;
        left: 1.125rem;
        position: absolute;
        transition-delay: 0s;
        top: 0.125rem;
        width: 0.125rem;
      }

      &:nth-child(2) {
        height: 0.125rem;
        left: 0.125rem;
        position: absolute;
        transition-delay: 0.25s;
        top: 1.125rem;
        width: 0;
      }
    }
  }

  &.open {
    display: flex !important;

    span {
      background-color: rgb(1255, 255, 255);
    }

    .hamburger {
      span {
        width: 0;

        &:nth-child(1) {
          transition-delay: 0s;
        }

        &:nth-child(2) {
          transition-delay: 0.125s;
        }

        &:nth-child(3) {
          transition-delay: 0.25s;
        }
      }
    }

    .cross {
      span {
        &:nth-child(1) {
          height: 85%;
          transition-delay: 0.625s;
        }

        &:nth-child(2) {
          transition-delay: 0.375s;
          width: 85%;
        }
      }
    }
  }

  &:active,
  &.active,
  &:focus,
  &:hover {
    background: rgba(39, 189, 47, 0.74);
    outline: 0;
    transition: 0.5s ease-in-out;

    span {
      background-color: white;
      transition: 0.5s ease-in-out;
    }

    &.open {
      background: rgba(0, 0, 0, 0.25);
    }
  }
`;

// Slider details Text Animation
const slideDown = keyframes`
  0% {
    opacity: 0.5;
    height: 0;
  }

  100% {
    opacity: 1;
    height: t100vh;
  }
`;

export const OverlayMenuContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(0.85rem);
  display: flex;
  flex-direction: column;
  height: 0;
  justify-content: center;
  left: 0;
  overflow-y: hidden;
  position: fixed;
  text-align: center;
  transition: height, cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.25s;
  top: 0;
  width: 100%;
  z-index: 1;

  a {
    color: #2b2b2b;
    display: block;
    font-size: 2rem;
    padding: 0.5rem;
    position: relative;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &::after {
      border-bottom: solid 1px rgba(200, 200, 200, 0.5);
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      width: 50%;
    }

    &:last-child {
      &::after {
        content: '';
        background: transparent;
      }
    }

    &.call-to-action {
      color: #a7d6a7;
      font-weight: bolder;
    }

    &:active,
    &:hover,
    :focus {
      background: rgba(255, 255, 255, 0.2);
      color: black;
      font-weight: bolder;
      transition: all 0.5s ease-in-out;
    }
  }

  @media screen and (max-height: small) {
    overflow-y: auto;

    a {
      font-size: 1.25rem;
    }
  }

  &.open {
    display: flex;
    height: 100vh;
    animation: 1s ease-in-out forwards ${slideDown};
  }
`;

export const NavToggleWrapper = styled.div`
  margin-left: 0;

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.medium}) {
    margin-left: 1rem;

    ${ToggleButtonWrapper} {
      display: flex;
    }
  }
`;

export const PublicNavWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 0;
  padding: 0;

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: flex-end;
    flex-grow: 1;
    padding: 0;
    margin: 0;

    li {
      margin: 0;
      display: inline-block;
    }

    a {
      border-bottom: solid 0.125rem transparent;
      color: #2b2b2b;
      display: block;
      font-size: 1rem;
      letter-spacing: 0.125rem;
      margin: 0;
      padding: 2.5rem 2rem;
      text-decoration: none;
      transition: all 1.5s ease-in-out;

      &.call-to-action {
        background: #27bd2f;
        color: white;
        display: inline-block;
      }

      &:hover,
      &:focus,
      &:active {
        border-bottom: solid 0.125rem #27bd2f;
        color: #27bd2f;
        font-weight: bold;
        transition: all 0.8s ease-in-out;

        &.call-to-action {
          background: #7ddc82;
          color: black;
        }
      }
    }

    @media all and (max-width: ${(props): string =>
        props.theme.breakpoints.medium}) {
      a {
        display: none;
      }
    }

    @media all and (max-width: ${(props): string =>
        props.theme.breakpoints.small}) {
      background: blue;
      display: none;
    }
  }
`;

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  transition: 0.5s ease-in-out;

  .brand-logo {
    display: flex;

    svg {
      width: 8rem;

      path {
        fill: #2b2b2b;
      }

      &:hover {
        #icon {
          fill: #000;
        }

        #text path {
          fill: #27bd2f;
        }
      }
    }
  }

  .brand-light {
    display: none;
  }

  @media all and (min-width: ${(props): string =>
      props.theme.breakpoints.wide}) {
    width: 75%;
    transition: 0.5s ease-in-out;
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.wide}) {
    padding: 0 1rem;
    transition: 0.5s ease-in-out;
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.medium}) {
    padding: 0 1rem;
    transition: 0.5s ease-in-out;
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.small}) {
    padding: 0.75rem 1rem;
    transition: 0.5s ease-in-out;
  }
`;

const HeaderWrapper = styled.header`
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  left: 0;
  opacity: 1;
  padding: 0;
  position: fixed;
  right: 0;
  z-index: 100;
`;

export default HeaderWrapper;
