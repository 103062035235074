import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface ErrorProps {
  message: string;
  type?: number;
}

const Error = ({ message, type = 404 }: ErrorProps): JSX.Element => {
  const { pathname } = useLocation();
  return (
    <section id="error" style={{ height: '100vh', border: 'solid 1px yellow' }}>
      <h1>{`Error: ${type}`}</h1>
      <p>{message}</p>
      <p>
        Location &nbsp;
        <strong>{pathname}</strong>
        &nbsp; was not found
      </p>
      <Link to="/">Go home</Link>
    </section>
  );
};

export default Error;
