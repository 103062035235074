import React from 'react';

import { FooterWrapper, FooterRow } from 'Components/front/styled-common';

/**
 * Public Facing Footer
 */
const Footer = (): JSX.Element => (
  <FooterWrapper>
    <FooterRow>
      <div>&copy; Lukaya Sacco & Onooli</div>
      <strong>{new Date().getFullYear()}</strong>
      <div>
        Powered with love by &nbsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.onooli.com/"
          className="to-onooli"
        >
          Onooli
        </a>
      </div>
    </FooterRow>
  </FooterWrapper>
);

export default Footer;
