import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import PublicRoutes from './public';
import ProtectedRoutes from './protected';

const AppRoutes = (): JSX.Element => (
  <BrowserRouter>
    <Switch>
      <Route path="/in">
        <ProtectedRoutes />
      </Route>
      <Route path="/">
        <PublicRoutes />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default AppRoutes;
