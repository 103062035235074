import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import { LupeerSVG } from 'Components/front/Logos';

import HeaderWrapper, {
  PublicNavWrapper,
  HeaderContainer,
  NavToggleWrapper,
  ToggleButtonWrapper,
  OverlayMenuContainer,
} from './styled';

const navMenuItems: {
  label: string;
  path: string;
  className?: string;
}[] = [
  { label: 'Home', path: 'home#welcome' },
  { label: 'About', path: 'home#about' },
  { label: 'FAQs', path: 'home#faqs' },
  { label: 'Login', path: '/in/', className: 'call-to-action' },
];

const NavToggle = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (e: React.MouseEvent): void => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const toggleButtonNode = useRef<HTMLButtonElement>(null);

  // Monitor Click outside button to close the Menu if (X) is not closed
  useEffect((): (() => void) => {
    const handleClick = (e: MouseEvent): void => {
      if (
        toggleButtonNode &&
        toggleButtonNode.current &&
        toggleButtonNode.current.contains(e.target as Node)
      ) {
        // Do nothing
      } else {
        // Close Menu on Clicking Anywhere
        setIsOpen(!isOpen);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClick);
    } else {
      document.removeEventListener('click', handleClick);
    }

    // Remove listener on unmount
    return (): void => {
      document.removeEventListener('click', handleClick);
    };
  }, [isOpen]);

  return (
    <NavToggleWrapper>
      <ToggleButtonWrapper
        ref={toggleButtonNode}
        type="button"
        className={isOpen ? 'open' : ''}
        onClick={handleToggle}
      >
        <div className="hamburger">
          <span />
          <span />
          <span />
        </div>
        <div className="cross">
          <span />
          <span />
        </div>
      </ToggleButtonWrapper>
      {isOpen && (
        <OverlayMenuContainer className={isOpen ? 'open' : ''}>
          {navMenuItems.map(
            ({ label, path, className }): JSX.Element =>
              className ? (
                <Link key={label} className={className} to={path}>
                  {label}
                </Link>
              ) : (
                <NavHashLink key={label} smooth to={path}>
                  {label}
                </NavHashLink>
              )
          )}
        </OverlayMenuContainer>
      )}
    </NavToggleWrapper>
  );
};

const Header = (): JSX.Element => (
  <HeaderWrapper>
    <HeaderContainer className="header-container">
      {/* -- Brand Logos--*/}
      <Link to="/" className="brand-logo">
        <LupeerSVG />
      </Link>
      {/* --------Navigation--*/}
      <PublicNavWrapper>
        <ul>
          {navMenuItems.map(
            ({ label, path, className }): JSX.Element => (
              <li key={label}>
                {className ? (
                  <Link key={label} className={className} to={path}>
                    {label}
                  </Link>
                ) : (
                  <NavHashLink smooth to={path}>
                    {label}
                  </NavHashLink>
                )}
              </li>
            )
          )}
        </ul>
      </PublicNavWrapper>
      <NavToggle />
    </HeaderContainer>
  </HeaderWrapper>
);

export default Header;
