import styled, { keyframes } from 'styled-components';

// Slider details Text Animation
const blendIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(-0.5rem, -8.5rem);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

// Next & Prev nav buttons pulse
const buttonPulse = keyframes`
  0% {
    opacity: 1;
    transform: scale(1, 1);
  }

  50% {
    opacity: 0.5;
    transform: scale(1.1, 1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
`;

export const SlideDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin: 0 auto;
  overflow-y: visible;
  padding: 4rem 2rem;
  text-align: center;
  width: 50%;

  * {
    backdrop-filter: blur(0.02rem);
    background: rgba(255, 255, 255, 0.8);
    color: black;
    font-weight: bold;
    margin: 0.05rem 0;
    top: -5rem;
  }

  h1 {
    font-size: 3.5rem;
    letter-spacing: 0.25rem;
    padding: 1rem 4rem;
  }

  h2 {
    background: rgba(255, 255, 255, 0.8);
    color: black;
    font-size: 2rem;
    padding: 0.25rem 1rem;
  }

  p {
    font-size: 1.5rem;
    padding: 0.25rem 1rem;
  }

  a {
    background: black;
    border-radius: 2rem;
    color: white;
    font-size: 1.5rem;
    letter-spacing: 0.08rem;
    margin-top: 2rem;
    padding: 0.25rem 2rem;
    transition: all 0.5s ease-in-out;

    &:active,
    &:hover,
    :focus {
      background: #7ddc82;
      box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.5);
      color: black;
      font-weight: bolder;
      transition: all 0.5s ease-in-out;
    }
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.medium}) {
    width: 85%;
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.small}) {
    width: 100%;
    height: auto;

    h1 {
      font-size: 2.5rem;
      padding: 1rem 1rem;
    }

    h2 {
      font-size: 1.5rem;
    }

    p,
    a {
      font-size: 1rem;
    }
  }
`;

export const SlideItemContainer = styled.li`
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  display: flex;
  left: 0;
  list-style-type: none;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 1s ease-in-out;

  &[aria-hidden='true'] {
    opacity: 0;
    z-index: -1;
    transition: opacity 1.5s ease-in-out;
  }

  &[aria-hidden='false'] {
    ${SlideDetailsContainer} {
      * {
        opacity: 0;
      }

      a,
      p,
      h1,
      h2 {
        animation: 1s ease-in-out forwards ${blendIn};
      }

      h1 {
        animation-delay: 1s;
      }

      p {
        animation-delay: 0.8s;
      }

      h2 {
        animation-delay: 0.3s;
      }

      a {
        animation-delay: 0s;
      }
    }
  }
`;

export const SlidesContainer = styled.ul`
  margin: 0;
`;

export const SlidesNavContainer = styled.nav`
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 3;

  button {
    backdrop-filter: blur(0.02rem);
    background: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 2rem;
    color: #000;
    cursor: pointer;
    display: block;
    font-size: 2rem;
    line-height: 2rem;
    position: absolute;
    text-align: center;
    transition: all 0.5s ease-in-out;
    width: 4.5rem;

    &.prev {
      left: 2.5rem;
    }

    &.next {
      right: 2.5rem;
    }

    &:active,
    &:hover,
    :focus {
      animation: 1.5s ease-in-out infinite ${buttonPulse};
      background: #7ddc82;
      color: black;
      font-weight: bolder;
      outline: none;
      transition: all 0.8s ease-in-out;
    }
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.medium}) {
    button {
      font-size: 1.8rem;
      line-height: 1.4rem;
      width: 3rem;

      &.prev {
        left: 1rem;
      }

      &.next {
        right: 1rem;
      }
    }
  }
`;

export const SlidesIndicatorContainer = styled.div`
  position: absolute;
  bottom: 8vmin;
  left: 0;
  right: 0;
  width: 80%;
  margin: 0 auto;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 1rem;
    margin-right: 1rem;

    background: rgba(255, 255, 255, 0.8);
    border: solid 2px #aaa;
    cursor: pointer;

    display: block;
    transition: all 2s ease-in-out;

    &.current {
      box-shadow: 0 0 0 2px white;
      background: black;
    }

    &:active,
    &:hover,
    :focus {
      background: black;
      border: solid 2px #ddd;
      color: black;
      font-weight: bolder;
      outline: none;
      transition: all 0.8s ease-in-out;
      animation: 1.5s ease-in-out infinite ${buttonPulse};
    }

    &.control {
      align-items: center;
      text-align: center;
      line-height: 1.5rem;
      font-size: 1.5rem;
      padding: 0;
      font-weight: bolder !important;
      border: none;
      background: none;
      position: relative;

      i {
        position: absolute;
        left: 0.15rem;
        top: -0.15rem;

        &::before {
          font-family: 'linea-music-10', sans-serif !important;
          background: black;
          color: white;
          border-radius: 2rem;
          line-height: 1.5rem;
          transition: all 0.5s ease-in-out;
        }
      }

      &:active,
      &:hover,
      :focus {
        animation: 1.5s ease-in-out infinite ${buttonPulse};

        i::before {
          background: #7ddc82;
          color: black;
          transition: all 0.8s ease-in-out;
        }
      }
    }
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.medium}) {
    li {
      height: 0.85rem;
      width: 0.85rem;
    }
  }
`;

export const ProgressBarContainer = styled.div`
  background: #eee;
  bottom: 0;
  height: 0.125rem;
  left: 0;
  position: absolute;
  width: 0;
`;

const CarouselWrapper = styled.section`
  bottom: 0;
  display: block;
  height: 50vh;
  left: 0;
  min-height: 50vh;
  position: relative;
  right: 0;
  top: 0;
  overflow-y: visible;

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.small}) {
    height: auto;
  }
`;

export default CarouselWrapper;
