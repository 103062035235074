import React, { useEffect, useReducer, useRef } from 'react';
import { Link } from 'react-router-dom';

import slides, { SlideItemType } from './slides';
import useProgress, { VoidReturnType } from './useProgress';

import ImageSliderReducer, {
  StateType,
  ActionChoices,
  ActionType,
} from './ImageSliderReducer';

import CarouselWrapper, {
  SlidesContainer,
  SlideItemContainer,
  SlideDetailsContainer,
  SlidesNavContainer,
  SlidesIndicatorContainer,
  ProgressBarContainer,
} from './styled';

interface SlideDetailsProps {
  content: SlideItemType;
}

const SlideDetails = ({ content }: SlideDetailsProps): JSX.Element => {
  const { title, subtitle, description, action } = content;
  return (
    <SlideDetailsContainer>
      <h2>{title}</h2>
      <h1>{subtitle}</h1>
      <p>{description}</p>
      {action && <Link to={action.path}>{action.text}</Link>}
    </SlideDetailsContainer>
  );
};

interface SlideItemProps {
  index: number;
  isCurrent: boolean;
  content: SlideItemType;
  takeFocus: boolean;
}

const SlideItem = ({
  content,
  index,
  isCurrent,
  takeFocus,
}: SlideItemProps): JSX.Element => {
  const { img } = content;
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (isCurrent && takeFocus) {
      if (ref.current !== null) {
        ref.current.focus();
      }
    }
  }, [isCurrent, takeFocus]);

  return (
    <SlideItemContainer
      ref={ref}
      aria-hidden={!isCurrent}
      tabIndex={-1}
      aria-labelledby={`slide-image-${index}`}
      style={{ backgroundImage: `url(${img})` }}
    >
      <SlideDetails content={content} />
    </SlideItemContainer>
  );
};

interface SlidesProps {
  children: React.ReactChild;
}

const Slides = ({ children }: SlidesProps): JSX.Element => (
  <SlidesContainer>{children}</SlidesContainer>
);

interface SlideNavProps {
  dispatch: React.Dispatch<ActionType>;
  slideCount: number;
}

const SlideNav = ({ dispatch, slideCount }: SlideNavProps): JSX.Element => {
  const { SET_NEXT, SET_PREVIOUS } = ActionChoices;
  return (
    <SlidesNavContainer>
      <button
        type="button"
        className="next"
        onClick={(): void => {
          dispatch({ type: SET_NEXT, slideCount });
        }}
      >
        <i className="arrows-arrows-slim-right" />
      </button>
      <button
        type="button"
        className="prev"
        onClick={(): void => {
          dispatch({ type: SET_PREVIOUS, slideCount });
        }}
      >
        <i className="arrows-arrows-slim-left" />
      </button>
    </SlidesNavContainer>
  );
};

interface IndicatorItemProps {
  dispatch: React.Dispatch<ActionType>;
  isCurrent: boolean;
  slideIndex: number;
}

const IndicatorItem = ({
  isCurrent,
  dispatch,
  slideIndex,
}: IndicatorItemProps): JSX.Element => {
  const { GOTO } = ActionChoices;
  return (
    <button
      type="button"
      className={isCurrent ? 'current' : ''}
      onClick={(): void => {
        dispatch({ type: GOTO, index: slideIndex });
      }}
    >
      &nbsp;
    </button>
  );
};

interface SlideIndicatorsProps {
  dispatch: React.Dispatch<ActionType>;
  isPlaying: boolean;
  children: React.ReactChild;
}

const SlideIndicators = ({
  isPlaying,
  dispatch,
  children,
}: SlideIndicatorsProps): JSX.Element => {
  const { PLAY, PAUSE } = ActionChoices;

  return (
    <SlidesIndicatorContainer>
      {children}
      <button
        type="button"
        className="control"
        onClick={(): void => {
          dispatch({ type: isPlaying ? PAUSE : PLAY, index: 1 });
        }}
      >
        <i className={`icon-music-${isPlaying ? 'pause' : 'play'}-button`} />
      </button>
    </SlidesIndicatorContainer>
  );
};

interface ProgressBarProps {
  animate: boolean;
  time: number;
}

const ProgressBar = ({ animate, time }: ProgressBarProps): JSX.Element => {
  const progress = useProgress(animate, time);

  return <ProgressBarContainer style={{ width: `${progress * 100}%` }} />;
};

interface ImageSliderProps {
  className?: string;
  id?: string;
}

const ImageSlider = ({ id, ...props }: ImageSliderProps): JSX.Element => {
  const initialState: StateType = {
    currentItem: 0,
    isPlaying: true,
    takeFocus: false,
  };

  const [state, dispatch] = useReducer<React.Reducer<StateType, ActionType>>(
    ImageSliderReducer,
    initialState
  );

  const { isPlaying, currentItem, takeFocus } = state;
  const SLIDE_TIMEOUT = 5000;

  useEffect((): VoidReturnType => {
    if (isPlaying) {
      const timer = setTimeout(() => {
        dispatch({
          type: ActionChoices.IS_PROGRESS,
          slideCount: slides.length,
        });
      }, SLIDE_TIMEOUT);

      return (): void => clearTimeout(timer);
    }
    return undefined;
  }, [isPlaying, currentItem]);

  return (
    <CarouselWrapper id={id} {...props}>
      <Slides>
        <>
          {slides.map(
            (slide, index): JSX.Element => (
              <SlideItem
                key={slide.title}
                index={index}
                isCurrent={index === currentItem}
                takeFocus={takeFocus}
                content={slide}
              />
            )
          )}
        </>
      </Slides>
      <SlideNav dispatch={dispatch} slideCount={slides.length} />
      <SlideIndicators dispatch={dispatch} isPlaying={isPlaying}>
        <>
          {slides.map(
            ({ title }, index): JSX.Element => (
              <IndicatorItem
                key={title}
                dispatch={dispatch}
                isCurrent={currentItem === index}
                slideIndex={index}
              />
            )
          )}
        </>
      </SlideIndicators>
      <ProgressBar key={currentItem} animate={isPlaying} time={SLIDE_TIMEOUT} />
    </CarouselWrapper>
  );
};

export default ImageSlider;
