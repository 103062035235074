import styled from 'styled-components';

export const SectionTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 50%;
  border: solid 1px greens;
  background: yellows;
  margin: 0 auto;

  h1,
  h2 {
    text-align: center;
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.medium}) {
    background: pink;
  }
`;

export const SectionWrapper = styled.section`
  background: white;
  padding: 8rem 0 5rem;
  margin: 0;

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.medium}) {
    padding: 8rem 1rem 1rem;
  }
`;

export const FooterRow = styled.div`
  display: flex;
  margin: 0 auto;

  div,
  strong {
    width: 50%;
  }

  strong {
    box-sizing: border-box;
    text-align: center;
  }

  div:last-child {
    text-align: right;
  }

  .to-onooli {
    background: transparent;
    padding: 0.25rem 0;
    border-radius: 0.25rem;
    transition: all 0.25s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      background: #ffc2a5;
      color: black;
      font-weight: bolder;
      padding: 0.25rem 1rem;
      transition: all 0.5s ease-in-out;
    }
  }

  @media all and (min-width: ${(props): string =>
      props.theme.breakpoints.wide}) {
    width: 75%;
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.small}) {
    flex-direction: column;
    width: 100%;

    div,
    strong {
      width: 100%;
      text-align: center !important;
      padding: 0.5rem 0;
    }
  }
`;

export const FooterWrapper = styled.footer`
  background: #2b2b2b;
  color: white;
  color: #bfbfbf;
  padding: 2rem 1rem;
`;
