import React, { useContext, useState, FormEvent } from 'react';
import { Link } from 'react-router-dom';

import AuthContext from 'Context/AuthContext';

import {
  FormInfo,
  FormInput,
  FormSubmit,
  CheckboxBase,
  OptionInputWrapper,
} from 'Components/front/styled-login';

import LoginTemplate, { LoginFormItemsType } from './LoginTemplate';
import ProgressIndicator from './ProgressIndicator';

const Login = (): JSX.Element => {
  // ToDo:: Route to previous history-path that prompted login attempt

  const { isAuth, login } = useContext(AuthContext);
  const [authInProgress, setAuthInProgress] = useState(false);

  const [authError, setAuthError] = useState('');
  const [cleanSlate, setCleanSlate] = useState(true);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleUsernameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    e.preventDefault();
    if (e.currentTarget) {
      setUsername(e.currentTarget.value);
    }
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    e.preventDefault();
    if (e.currentTarget) {
      setPassword(e.currentTarget.value);
    }
  };

  async function handleSubmit(e: FormEvent): Promise<boolean> {
    e.preventDefault();

    if (login) {
      setAuthInProgress(true);
      setCleanSlate(true);

      const loginFeedback = await login({ username, password, rememberMe });

      if (loginFeedback.isAuth) {
        return true;
      }

      setAuthError(loginFeedback.message);
      setAuthInProgress(false);
      setCleanSlate(false);
    }

    return false;
  }

  const items: LoginFormItemsType[] = [
    ...(authInProgress
      ? [
          {
            name: 'form-authenticating',
            Component: <ProgressIndicator title="Signing In, please wait..." />,
          },
        ]
      : [
          ...(!isAuth && !cleanSlate
            ? [
                {
                  name: 'form-info',
                  Component: (
                    <FormInfo>
                      <i>i</i>
                      <span>{authError}</span>
                    </FormInfo>
                  ),
                },
              ]
            : []),
          {
            name: 'form-username',
            Component: (
              <FormInput
                type="text"
                name="username"
                placeholder="Username or Email..."
                value={username}
                onChange={handleUsernameChange}
                required
              />
            ),
          },
          {
            name: 'form-password',
            Component: (
              <FormInput
                type="password"
                name="password"
                placeholder="Your Password..."
                value={password}
                onChange={handlePasswordChange}
                required
              />
            ),
          },
          {
            name: 'form-submit',
            Component: (
              <FormSubmit type="submit" onClick={(): null => null}>
                Log In
              </FormSubmit>
            ),
          },
        ]),
  ];

  return (
    <LoginTemplate
      title="Login"
      items={items}
      errorClass={authError ? 'hasError' : ''}
      handleSubmit={handleSubmit}
      isAuth={isAuth}
      footer={
        !authInProgress && (
          <>
            <Link to="/forgot-password">Forgot Password ?</Link>
            <OptionInputWrapper className="">
              <CheckboxBase
                name="rememberMe"
                id="rememberMe"
                checked={rememberMe}
                onChange={(): void => setRememberMe(!rememberMe)}
              />
              <label htmlFor="rememberMe">
                <span>Keep Me Signed In</span>
              </label>
            </OptionInputWrapper>
          </>
        )
      }
    />
  );
};

export default Login;
