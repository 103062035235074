import React from 'react';

// import 'Assets/css/bootstrap.css';
// import 'Assets/css/linea-arrows.css';
// import 'Assets/css/linea-music.css';
import 'Assets/css/linea-icons.css';
// import 'Assets/css/pace-theme-minimal.css';
// import 'Assets/css/template.css';
// import 'Assets/css/template-sacco-green.css';

import ImageSlider from 'Components/front/ImageSlider';
import Header from 'Components/front/Header';

import HomeWrapper from 'Components/front/styled-home';

import About from '../About';
import FAQs from '../FAQs';
import Footer from '../Footer';

const GoToTop = (): JSX.Element => (
  <a className="scroll-top" href="#top">
    <i className="arrows arrows-arrows-up" />
  </a>
);

const Home = (): JSX.Element => (
  <HomeWrapper id="welcome">
    <Header />
    <ImageSlider />
    <About />
    <FAQs />
    <Footer />
    <GoToTop />
  </HomeWrapper>
);

export default Home;
