const theme = {
  constants: {
    sidebarWidth: '20rem',
  },
  light: {
    primary: 'darkbrown',
    secondary: 'purple',
    focus: 'green',
    inactive: 'aaa',
    bg: 'transparent', // Background Color
    fg: 'dimgray', // Foreground Color
    border: 'aaa',
  },
  text: {
    primary: '#2b2b2b',
    secondary: '#eee',
    alt: '#67717A',
    placeholder: '#7C8894',
    reverse: '#FFFFFF',
  },
  dark: {},
  warn: {},
  success: {},
  info: {},
  breakpoints: {
    obese: '90rem', // 11440px
    wide: '85rem', // 1366px
    huge: '80rem', // 1280px
    large: '73.125rem', // 1170px
    medium: '48rem', // 768px
    small: '28.125rem', // 450px
    tiny: '22.5rem', // 360px
  },
};

export default theme;
