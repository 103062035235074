import React, { FormEvent } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { animated, interpolate, useTrail } from 'react-spring';

import { LupeerTextSVG } from 'Components/front/Logos';

import LoginPageWrapper, {
  FormContainer,
  FormBody,
  FormFooter,
  FormHeader,
} from 'Components/front/styled-login';

export interface LoginFormItemsType {
  name: string;
  Component: JSX.Element;
}

interface LoginTemplateProps {
  errorClass?: string;
  footer?: JSX.Element | false;
  handleSubmit: (e: FormEvent) => Promise<boolean>;
  isAuth?: boolean;
  items: LoginFormItemsType[];
  title: string;
}

const LoginTemplate = ({
  errorClass = '',
  footer,
  handleSubmit,
  isAuth = false,
  items,
  title,
}: LoginTemplateProps): JSX.Element => {
  const config = { mass: 5, tension: 2000, friction: 200 };
  const trailProps: {
    config: typeof config;
    opacity: number;
    x: number;
    height: string | number;
    from: {
      opacity: number;
      x: number;
      height: string | number;
    };
  } = {
    config,
    opacity: 1,
    x: 0,
    height: 'auto',
    from: { opacity: 0, x: -25, height: 0 },
  };

  const formHeaderItem = {
    name: 'form-header',
    Component: (
      <FormHeader>
        <h1>{title}</h1>
        <Link to="/">
          <LupeerTextSVG />
        </Link>
      </FormHeader>
    ),
  };

  const displayItems = [formHeaderItem, ...items];

  const trail = useTrail(displayItems.length, trailProps);

  // ToDo: Add overflow hidden to login form to fix leaking right shadow on firefox.
  return !isAuth ? (
    <LoginPageWrapper>
      <FormContainer onSubmit={handleSubmit}>
        <FormBody className={errorClass}>
          {trail.map(({ x, height, ...rest }, index) => {
            const { name, Component } = displayItems[index];
            return (
              <animated.div
                className="item"
                key={name}
                style={{
                  ...rest,
                  transform: interpolate(
                    [x],
                    xVal => `translate3d(0,${xVal}px,0)`
                  ),
                }}
              >
                {Component}
              </animated.div>
            );
          })}
        </FormBody>
        {footer && <FormFooter>{footer}</FormFooter>}
      </FormContainer>
    </LoginPageWrapper>
  ) : (
    <Redirect to="/in" />
  );
};

export default LoginTemplate;
