import React, { useState } from 'react';

type LoginFeedbackType =
  | {
      isAuth: true;
    }
  | {
      isAuth: false;
      code: number;
      message: string;
    };
interface LoginOptions {
  username: string;
  password: string;
  rememberMe: boolean;
}
interface AppContextInterface {
  isAuth: boolean;

  login?(options: LoginOptions): Promise<LoginFeedbackType>;

  logout?(): Promise<boolean>;
}

const AuthContext = React.createContext<AppContextInterface>({
  isAuth: false,
});

interface AuthProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProps): JSX.Element => {
  const [isAuth, setIsAuth] = useState(!!localStorage.getItem('current-user'));
  const fakeWait = 5000;

  const setTimeoutAsync = (waitTime: number): Promise<unknown> =>
    new Promise(resolve => setTimeout(resolve, waitTime));

  const login = async ({
    username,
    password,
    rememberMe,
  }: LoginOptions): Promise<LoginFeedbackType> => {
    localStorage.setItem('current-user', username);
    localStorage.setItem('current-user-date', Date.now().toString());

    console.log({ username, password, rememberMe });

    if (rememberMe) {
      localStorage.setItem('current-remember-me', `${rememberMe}`);
    }

    await setTimeoutAsync(fakeWait);

    // Fake Random Login API Call...
    if (Math.round(Math.random()) === 1) {
      setIsAuth(true);

      return { isAuth: true };
    }

    setIsAuth(false);

    return {
      isAuth: false,
      code: 401,
      message: 'Invalid username or password',
    };
  };

  const logout = async (): Promise<boolean> => {
    localStorage.removeItem('current-user');
    localStorage.removeItem('current-user-date');
    localStorage.removeItem('current-remember-me');

    await setTimeoutAsync(fakeWait);
    setIsAuth(false);

    return isAuth;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
