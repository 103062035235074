import image1 from 'Assets/img/slider/01-holding-money.svg';
import image2 from 'Assets/img/slider/02-coins.jpg';

export interface SlideItemType {
  img: string;
  title: string;
  subtitle: string;
  description: string;
  action: {
    text: string;
    path: string;
  };
}

const slides: SlideItemType[] = [
  {
    img: image1,
    title: 'Lukaya',
    subtitle: 'SACCO',
    description: 'A Financial Cooperative Association',
    action: {
      text: '"Agali Awamu!"',
      path: './contacts',
    },
  },
  {
    img: image2,
    title: 'For',
    subtitle: 'Shares,  Savings, Loans',
    description: 'Want to Join our Circles, become a member',
    action: {
      text: 'Learn More',
      path: './team',
    },
  },
  {
    img: image2,
    title: '"Eyeterekera"',
    subtitle: 'Get Shares',
    description:
      'Become a shareholder, buy shares and let your money work for you! Year in, Year out.',
    action: {
      text: 'Wish to Know how?',
      path: './contacts',
    },
  },
];

export default slides;
