import React from 'react';

import { ProgressWrapper, FormInfo } from 'Components/front/styled-login';

const ProgressLoader = (): JSX.Element => (
  <div className="loader">
    <svg viewBox="0 0 1200 400" xmlns="http://www.w3.org/2000/svg">
      <g className="paths" fillRule="evenodd" transform="translate(0, 80)">
        <path
          id="circlePath"
          d="M 0, 60 S 300, -60, 600, 60, 800, -120, 1200 60"
        />
        <path d="M 0, 50 S 200, -60, 400, 60, 900, -120, 1200 50" />
        <path d="M 0, 0 S 200, -70, 400, 70, 800, -120, 1200 60" />
        <path d="M 0, 70 S 200, -60, 400, 80, 600, -120, 1200 70" />
      </g>
      <circle id="glow" r="2" cx="0" cy="80" />
      <animateMotion
        xlinkHref="#glow"
        dur="4s"
        begin="1.2s"
        repeatCount="indefinite"
      >
        <mpath xlinkHref="#circlePath" />
      </animateMotion>
    </svg>
  </div>
);

interface ProgressIndicatorProps {
  title: string;
}

const ProgressIndicator = ({ title }: ProgressIndicatorProps): JSX.Element => (
  <ProgressWrapper>
    <FormInfo>
      <i>.</i>
      <span>{title}</span>
    </FormInfo>
    <ProgressLoader />
  </ProgressWrapper>
);

export default ProgressIndicator;
