import { useState, useEffect } from 'react';

export type VoidReturnType = (() => void) | undefined;

const useProgress = (animate: boolean, time: number): number => {
  const [progress, setProgress] = useState(0);

  useEffect((): VoidReturnType => {
    if (animate) {
      let rafId: number;
      let start: number;

      const step = (timestamp: number): void => {
        if (!start) start = timestamp;
        const currentProgress = timestamp - start;

        setProgress(currentProgress);

        if (currentProgress < time) {
          rafId = requestAnimationFrame(step);
        }
      };

      rafId = requestAnimationFrame(step);

      return (): void => cancelAnimationFrame(rafId);
    }
    return undefined;
  }, [animate, time]);

  return animate ? Math.min(progress / time, time) : 0;
};

export default useProgress;
