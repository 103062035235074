import React, { useContext } from 'react';
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import AuthContext from 'Context/AuthContext';

const Dashboard = (): JSX.Element => (
  <section>
    <h1>Dashboard</h1>
    <p>Summary of cool stuff</p>
  </section>
);

const Account = (): JSX.Element => (
  <section>
    <h1>Account</h1>
    <p>Your dimes start here</p>
  </section>
);

interface UserProps {
  type: string;
}

const Users = ({ type }: UserProps): JSX.Element => (
  <section>
    <h1>Users</h1>
    <p>{`Type :${type}`}</p>
  </section>
);

const Error = (): JSX.Element => {
  const { pathname } = useLocation();
  return (
    <section>
      <h1>App Error</h1>
      <p>
        Location &nbsp;
        <strong>{pathname}</strong>
        &nbsp; was not found
      </p>
    </section>
  );
};

const ProtectedRoutes = (): JSX.Element => {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const { isAuth, logout } = useContext(AuthContext);

  const userAdmin = false;
  const userTeller = false;

  return isAuth ? (
    <div style={{ color: '#2b2b2b !important', background: 'black' }}>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <NavLink to={url}>Dashboard</NavLink>
        </li>
        <li>
          <NavLink to={`${url}/accounts`}>Account</NavLink>
        </li>
        <li>
          <button type="button" onClick={logout}>
            Signout
          </button>
        </li>
      </ul>

      <Switch>
        <Route path={`${path}/accounts`}>
          <Account />
        </Route>
        <Route exact path={path}>
          <Dashboard />
        </Route>

        {// ToDo:Testing Route: Refactor later
        userAdmin ? (
          <>
            <Route path="/admin/">
              <Users type="admin" />
            </Route>
          </>
        ) : null}
        {userTeller ? (
          <Route path="/teller">
            <Users type="teller" />
          </Route>
        ) : null}

        <Route path="*">
          <Error />
        </Route>
      </Switch>
    </div>
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
};

export default ProtectedRoutes;
