import React from 'react';

import { ReactComponent as QuestionIcon } from 'Assets/icons/basic_question.svg';
import { ReactComponent as CalculatorIcon } from 'Assets/icons/basic_calculator.svg';
import { ReactComponent as CompassIcon } from 'Assets/icons/basic_compass.svg';
import { ReactComponent as EyeIcon } from 'Assets/icons/basic_eye.svg';

import SectionTitle from 'Components/front/SectionTitle';
import CardLanding from 'Components/front/Cards/CardLanding';

import { SectionWrapper } from 'Components/front/styled-common';
import { CardRow } from 'Components/front/Cards/styled';

/**
 * About Us section of the homepage
 */
const AboutUs = (): JSX.Element => (
  <SectionWrapper id="about">
    <SectionTitle
      title="Built for Communities"
      subtitle="We get societies to work together. In the Now, On the Line"
    />
    <CardRow>
      <CardLanding
        icon={<QuestionIcon />}
        title="Who we Are"
        description="Well, Lorem ipsum dolor sit amet consectetuer adipiscing elit sed diam lorem ipsum dolor sit amet."
        iconClass="icon-basic-question"
        action={{ title: 'Meet the People', path: '/who-we-are' }}
      />
      <CardLanding
        icon={<CalculatorIcon />}
        title="What we Do!"
        description="Well, Lorem ipsum dolor sit amet consectetuer adipiscing elit sed diam lorem ipsum dolor sit amet."
        iconClass="icon-basic-calculator"
        action={{ title: 'Read More', path: '/what-we-do' }}
      />
    </CardRow>
    <CardRow>
      <CardLanding
        icon={<CompassIcon />}
        title="Mission"
        description="Well, Lorem ipsum dolor sit amet consectetuer adipiscing elit sed diam lorem ipsum dolor sit amet."
        iconClass="icon-basic-compass"
        action={{ title: 'Take a Tour', path: '/mission' }}
      />
      <CardLanding
        icon={<EyeIcon />}
        title="Vision"
        description="Well, Lorem ipsum dolor sit amet consectetuer adipiscing elit sed diam lorem ipsum dolor sit amet."
        iconClass="icon-basic-eye"
        action={{ title: 'And Beyond', path: '/vision' }}
      />
    </CardRow>
  </SectionWrapper>
);

export default AboutUs;
