import { Reducer } from 'react';

export interface StateType {
  currentItem: number;
  isPlaying: boolean;
  takeFocus: boolean;
}

export enum ActionChoices {
  IS_PROGRESS = 'IS_PROGRESS',
  SET_NEXT = 'SET_NEXT',
  SET_PREVIOUS = 'SET_PREVIOUS',
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
  GOTO = 'GOTO',
}

export interface ActionType {
  type: 'IS_PROGRESS' | 'SET_NEXT' | 'SET_PREVIOUS' | 'PLAY' | 'PAUSE' | 'GOTO';
  index?: number;
  slideCount?: number;
}

const ImageSliderReducer: Reducer<StateType, ActionType> = (
  state,
  action
): StateType => {
  switch (action.type) {
    case ActionChoices.IS_PROGRESS:
    case ActionChoices.SET_NEXT: {
      const { type, slideCount = 0 } = action;

      return {
        ...state,
        takeFocus: false,
        isPlaying: type === ActionChoices.IS_PROGRESS,
        currentItem: (state.currentItem + 1) % slideCount,
      };
    }

    case ActionChoices.SET_PREVIOUS: {
      const { slideCount = 0 } = action;

      return {
        ...state,
        takeFocus: false,
        isPlaying: false,
        currentItem: (state.currentItem - 1 + slideCount) % slideCount,
      };
    }

    case ActionChoices.PLAY: {
      return {
        ...state,
        takeFocus: false,
        isPlaying: true,
      };
    }
    case ActionChoices.PAUSE:
      return {
        ...state,
        takeFocus: false,
        isPlaying: false,
      };
    case ActionChoices.GOTO: {
      const { index = 0 } = action;
      return {
        ...state,
        takeFocus: true,
        currentItem: index,
      };
    }

    default: {
      return state;
    }
  }
};

export default ImageSliderReducer;
