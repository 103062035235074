import styled from 'styled-components';

const CardWrapper = styled.div`
  background: transparent;
  border: solid 4px #fff;
  box-shadow: 0 0 0 transparent;
  margin: 0.25rem 1rem;
  padding: 2.5rem 1rem;
  position: relative;
  text-align: center;
  transition: all 1s ease-in-out;

  svg {
    border: solid 1px reds;
    height: 6rem;
    width: 6rem;
    margin-bottom: 2rem;
    padding: 0.25rem;
    overflow: visible;
    transition: all 0.25s ease-in-out;

    circle,
    line,
    path,
    polygon,
    rect {
      fill: none;
      stroke: #5ba000;
      stroke-width: 2;
      stroke-miterlimit: 10;
      transition: all 0.25s ease-in-out;
    }
  }

  h6 {
    font-size: 1.5rem;
    margin: 0 0 1rem;
    color: #5ba000;
    transition: all 0.25s ease-in-out;
  }

  p {
    color: #222;
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }

  i {
    font-size: 3.5rem;
    margin: 0 0 10px;
    color: #27bd2f;
    transition: all 0.25s ease-in-out;
  }

  a {
    color: #27bd2f;
    font-weight: bold;
    font-size: 0.85rem;
    letter-spacing: 1px;
    margin-top: 1rem;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
    transition: all 1s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      background-color: #27bd2f;
      border-color: #02da0e;
      color: #fff;
      transition: all 0.25s ease-in-out;
    }
  }

  .left-aligned & {
    text-align: left;

    i {
      font-size: 2.5rem;
      position: absolute;
      left: 1rem;
      top: 2rem;
    }

    h6,
    p,
    a {
      margin-left: 4rem;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #fbfbfb;
    border: solid 4px #fff;
    box-shadow: 0 2px 5px 1px rgba(144, 144, 144, 0.25);
    border-radius: 0.5rem;
    color: #fff;
    transition: all 0.25s ease-in-out;

    svg {
      text-shadow: 0px 1px red;
      transition: all 0.25s ease-in-out;

      circle,
      line,
      path,
      polygon,
      rect {
        stroke: #2b2b2b;
        stroke-width: 3;
        stroke-miterlimit: 1;
        transition: all 0.25s ease-in-out;
      }
    }

    h6 {
      color: #2b2b2b;
      font-size: 1.6rem;
      font-weight: bolder;
      transition: all 0.25s ease-in-out;
    }

    i {
      color: #000;
      text-shadow: 0px 1px #5ba000;
    }

    a {
      color: green;
      font-weight: bolder;
      letter-spacing: 2px;
      border: solid 1px #595;
    }
  }
`;

export const CardRow = styled.div`
  border: solid 1px reds;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  width: 100%;

  ${CardWrapper} {
    width: 25%;
    border: solid 1px blues;
  }

  @media all and (min-width: ${(props): string =>
      props.theme.breakpoints.wide}) {
    margin: 0 auto;
    max-width: 75%;
    width: 75%;

    ${CardWrapper} {
      width: 25%;
    }

    &.threes {
      ${CardWrapper} {
        width: 33%;
      }
    }
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.wide}) {
    ${CardWrapper} {
      width: 25%;
    }

    &.threes {
      ${CardWrapper} {
        width: 33%;
      }
    }
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.large}) {
    ${CardWrapper} {
      width: 50%;
    }

    &.twos {
      ${CardWrapper} {
        width: 50%;
      }
    }

    &.threes {
      ${CardWrapper} {
        width: 33%;
      }
    }

    &.fours {
      ${CardWrapper} {
        width: 50%;
      }
    }
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.medium}) {
    flex-wrap: wrap;
    padding: 0;

    ${CardWrapper} {
      width: 50%;
    }

    &.threes {
      ${CardWrapper} {
        width: 50%;
      }
    }
  }

  @media all and (max-width: ${(props): string =>
      props.theme.breakpoints.small}) {
    flex-direction: column;

    ${CardWrapper} {
      margin-bottom: 0.25rem;
      width: 100% !important;
    }
  }
`;

export default CardWrapper;
