import React from 'react';

interface PublicLayoutProps {
  children: React.ReactElement;
}

const PublicLayout = ({ children }: PublicLayoutProps): JSX.Element => (
  <>{children}</>
);

export default PublicLayout;
