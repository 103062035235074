import React, { useState, useEffect, FormEvent } from 'react';
import { Link } from 'react-router-dom';

import { FormInfo, FormInput, FormSubmit } from 'Components/front/styled-login';

import LoginTemplate, { LoginFormItemsType } from './LoginTemplate';
import ProgressIndicator from './ProgressIndicator';

interface PassWordResetOptions {
  email: string;
}

interface PassWordResetFeedbackType {
  success: boolean;
  code: number;
  message: string;
}

interface PostPasswordResetReturnType {
  fakePost: Promise<PassWordResetFeedbackType>;
  cancelPost: () => void;
}

/**
 * Fake Async Post Request with a timeout
 * Note that the promise is not cancellable, leaving stale state on unmount
 * ToDo: Fix this on real API, still buggy on unmount
 *  * Make the promise cancellable
 */
const postPasswordReset = ({
  email,
}: PassWordResetOptions): PostPasswordResetReturnType => {
  const fakeWait = 5000;
  let postTimeoutId: number;

  const onResolveFakePost = (): PassWordResetFeedbackType => {
    if (Math.round(Math.random()) === 1) {
      return {
        success: true,
        code: 200,
        message: `
          We have sent the link to "${email}". Use it to reset your Password.
          If you did not see it, please wait a few minutes or check your spam folder.
          If still no luck, generate a new link below.
        `,
      };
    }
    return {
      success: false,
      code: 505,
      message: `
        Sorry, we could not verify your Email "${email}".
        It looks like the Email you provided is not registered with us.
        Please contact our Customer Service to assist you,
        or try with a different email.
       `,
    };
  };

  const fakePost: Promise<PassWordResetFeedbackType> = new Promise(resolve => {
    postTimeoutId = setTimeout((): void => {
      resolve(onResolveFakePost());
    }, fakeWait);
  });

  return {
    fakePost,
    cancelPost: (): void => {
      clearTimeout(postTimeoutId);
    },
  };
};

const ForgotPassword = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [formTitle, setFormTitle] = useState('Forgot Password');

  const [cleanSlate, setCleanSlate] = useState(true);
  const [sendInProgress, setSendInProgress] = useState(false);
  const [feedback, setFeedback] = useState<PassWordResetFeedbackType>({
    success: false,
    code: 0,
    message: ``,
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();

    if (e.currentTarget) {
      setEmail(e.currentTarget.value);
    }
  };

  async function handleSubmit(e: FormEvent): Promise<boolean> {
    e.preventDefault();

    setFormTitle('Contacting Server');
    setSendInProgress(true);
    setCleanSlate(false);

    const postFeedback = await postPasswordReset({ email }).fakePost;

    setSendInProgress(false);
    setFormTitle('Done');

    setFeedback(postFeedback);

    if (postFeedback.success) {
      return true;
    }

    return false;
  }

  const forgotPasswordViewItems: LoginFormItemsType[] = [
    {
      name: 'form-info',
      Component: (
        <FormInfo className="help">
          <i>i</i>
          <span>
            We are going to send a password reset link to this email address.
          </span>
        </FormInfo>
      ),
    },
    {
      name: 'form-email',
      Component: (
        <FormInput
          type="email"
          name="email"
          placeholder="Enter your email address here..."
          required
          value={email}
          onChange={handleEmailChange}
        />
      ),
    },
    {
      name: 'form-submit',
      Component: (
        <FormSubmit type="submit" onClick={(): null => null}>
          Send Reset Link
        </FormSubmit>
      ),
    },
  ];

  const progressViewItem = [
    {
      name: 'form-authenticating',
      Component: (
        <ProgressIndicator title="Sending reset link, please wait..." />
      ),
    },
  ];

  const doneSendingViewItems: LoginFormItemsType[] = [
    {
      name: 'form-info',
      Component: (
        <FormInfo className={feedback.success ? 'success' : 'error'}>
          <i>{feedback.success ? '✔' : 'x'}</i>
          <span>{feedback.message}</span>
        </FormInfo>
      ),
    },
    {
      name: 'form-submit',
      Component: (
        <FormSubmit
          type="button"
          onClick={(
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
          ): void => {
            e.preventDefault();
            setCleanSlate(true);
          }}
        >
          Generate another Link
        </FormSubmit>
      ),
    },
  ];

  const showForgotPasswordView = cleanSlate;
  const showProgressBar = !cleanSlate && sendInProgress;

  // eslint-disable-next-line no-nested-ternary
  const items = showForgotPasswordView
    ? forgotPasswordViewItems
    : showProgressBar
    ? progressViewItem
    : doneSendingViewItems;

  // Cancel Post Request when unmounted
  // ToDo: Fix this on real API, still buggy on unmount
  useEffect(() => {
    return (): void => {
      if (email && sendInProgress) {
        postPasswordReset({ email }).cancelPost();
      }
    };
  }, [email, sendInProgress]);

  return (
    <LoginTemplate
      title={formTitle}
      items={items}
      handleSubmit={handleSubmit}
      footer={!sendInProgress && <Link to="/login">Back to Login</Link>}
    />
  );
};

export default ForgotPassword;
