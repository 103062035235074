import { createGlobalStyle } from 'styled-components';

import theme from './themes';

const { text } = theme;

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
  --font-family: 'Sofia Pro Light', 'Avenir Book', -apple-system, Montserrat,
    Lato, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  font: 100%/1em var(--font-family);
  font-family: 'Sofia Pro Light', 'Avenir Book', -apple-system, Montserrat, Lato,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  }

  body {
    background: white;
    border-top: solid 0 white;
    color: ${text.primary};
    font-weight: 400;
    height: 100vh;
    margin: 0;
    overflow: auto;
    padding: 0;
    transition: border-top ease-in-out 500ms;
    line-height: 1;
  }

  button,
  a {
    text-decoration: none;
    outline: none;
  }

  @media only screen and (min-width: 73.125rem) {
    &::-webkit-scrollbar {
      background: transparent;
      width: 0.25rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.5rem;
      background: #c8f1e6;
      box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
      cursor: grab;
      background: #5ef7d8;
    }
  }
`;

export default GlobalStyles;
