import React from 'react';
import { ReactComponent as _LogoSVG } from 'Assets/img/logos/sacco-logo.svg';
import { ReactComponent as _LupeerSVG } from 'Assets/img/logos/lupeer-logo.svg';
import { ReactComponent as _LupeerTextSVG } from 'Assets/img/logos/lupeer-text.svg';

export const LupeerSVG = React.memo(_LupeerSVG);

export const LupeerTextSVG = React.memo(_LupeerTextSVG);

const SaccoLogo = React.memo(_LogoSVG);

export default SaccoLogo;
