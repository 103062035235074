import React from 'react';

import { SectionTitleWrapper } from './styled-common';

interface SectionTitleProps {
  title: string;
  subtitle: string;
}

const SectionTitle = ({ title, subtitle }: SectionTitleProps): JSX.Element => (
  <SectionTitleWrapper>
    <h1>{title}</h1>
    <h2>{subtitle}</h2>
  </SectionTitleWrapper>
);

export default SectionTitle;
