import React from 'react';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

interface BlogParams {
  sectionId: string;
}

const BlogContent = (): JSX.Element => {
  const { sectionId } = useParams<BlogParams>();

  return (
    <div>
      <h1>Blog ---</h1>
      <h3>{sectionId}</h3>
    </div>
  );
};

const Blog = (): JSX.Element => {
  const { path, url, ...other } = useRouteMatch();
  const {
    location: { pathname },
  } = useHistory();
  console.log({ name: 'Blog Obj', path, url, other, pathname });
  return (
    <section>
      <h1>BLOG NATION</h1>
      <ul>
        <li>
          <Link to={`${url}/post-1`}>Post 1</Link>
        </li>
        <li>
          <Link to={`${url}/components`}>url-Components</Link>
        </li>
        <li>
          <Link to="/in">/In</Link>
        </li>
        <li>
          <Link to="/login">/Login</Link>
        </li>
      </ul>

      <Switch>
        <Route exact path={path}>
          <div>This Blog has all the Content you can dream of</div>
        </Route>
        <Route path={`${path}/:sectionId`}>
          <BlogContent />
        </Route>
      </Switch>
    </section>
  );
};

export default Blog;
