import React from 'react';

import SectionTitle from 'Components/front/SectionTitle';
import CardLanding from 'Components/front/Cards/CardLanding';

import { SectionWrapper } from 'Components/front/styled-common';

const ContactInfo = (): JSX.Element => (
  <>
    Office Location: Lukaya Town
    <br />
    Akezimbira Building: Level 2
    <br />
    P.0 Box &nbsp;
    <strong>2342 Masaka Rd</strong>
    <br />
    Tel: &nbsp;
    <strong>+27-700-000-000</strong>
    <br />
    Tel: &nbsp;
    <strong>+27-770-000-000</strong>
    <br />
    Email: &nbsp;
    <strong>info@sacco.com</strong>
  </>
);

/**
 * Frequently Asked Questions component of the homepage
 */
const FAQs = (): JSX.Element => (
  <SectionWrapper id="faqs" style={{ background: 'rgb(245,245,245)' }}>
    <SectionTitle title="FAQs" subtitle="All you wish to Know!" />
    <div className="cards-row threes left-aligned">
      <CardLanding
        title="How to become a Member"
        description="Especially do at he possession insensible sympathize boisterous
        it. Songs he on an widen me event truth."
        iconClass="icon-basic-heart"
      />
      <CardLanding
        title="Who can become a Member?"
        description="Especially do at he possession insensible sympathize boisterous
        it. Songs he on an widen me event truth."
        iconClass="icon-basic-target"
      />
      <CardLanding
        title="How to get Loans"
        description="Well, Lorem ipsum dolor sit amet consectetuer adipiscing elit sed diam lorem ipsum dolor sit amet."
        iconClass="icon-basic-notebook-pen"
      />
      <CardLanding
        title="How to buy Shares"
        description="Well, Lorem ipsum dolor sit amet consectetuer adipiscing elit sed diam lorem ipsum dolor sit amet."
        iconClass="icon-basic-mixer2"
      />
      <CardLanding
        title="Charges on Savings"
        description="Well, Lorem ipsum dolor sit amet consectetuer adipiscing elit sed diam lorem ipsum dolor sit amet."
        iconClass="icon-basic-watch"
      />
      <CardLanding
        title="How to Contact Us"
        description={<ContactInfo />}
        iconClass="icon-basic-question"
        action={{ title: 'Message Us', path: '/contact-us' }}
      />
    </div>
  </SectionWrapper>
);

export default FAQs;
