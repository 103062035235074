import styled from 'styled-components';

const HomeWrapper = styled.div`
  section {
    width: 100%;

    &.landing-slider {
      background: #aaa;
      margin: 0;
      padding: 0;
    }
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    color: #555;
    font-family: 'Lora', 'Libre Baskerville', serif;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: bold;
  }
`;

export default HomeWrapper;
