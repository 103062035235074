import React from 'react';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from 'Context/AuthContext';
import GlobalStyles from 'Styles';
import theme from 'Styles/themes';

import AppRoutes from 'Routes';

const AppSetup = (): JSX.Element => (
  <AuthProvider>
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <AppRoutes />
    </ThemeProvider>
  </AuthProvider>
);

export default AppSetup;
