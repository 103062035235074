import React from 'react';
import { Link } from 'react-router-dom';

import CardWrapper from './styled';

interface CardLandingProps {
  action?: {
    path: string;
    title: string;
  };
  description: string | React.ReactElement;
  icon?: React.ReactNode;
  iconClass: string;
  title: string;
}

const CardLanding = ({
  action,
  title,
  description,
  iconClass,
  icon,
}: CardLandingProps): JSX.Element => (
  <CardWrapper>
    {icon || <i className={iconClass} />}
    <h6>{title}</h6>
    <p>{description}</p>
    {action && (
      <Link to={action.path} role="link">
        {action.title}
      </Link>
    )}
  </CardWrapper>
);

export default CardLanding;
