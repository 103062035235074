import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PublicLayout from 'Layouts/public';

import About from 'Pages/front/About';
import Blog from 'Pages/front/Blog';
import Error from 'Pages/front/Error';
import ForgotPassword from 'Pages/front/Login/ForgotPassword';
import Home from 'Pages/front/Home';
import Login from 'Pages/front/Login';

const PublicRoutes = (): JSX.Element => {
  return (
    <PublicLayout>
      <>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="*">
            <Error
              type={404}
              message="Something is not right, page not found"
            />
          </Route>
        </Switch>
      </>
    </PublicLayout>
  );
};

export default PublicRoutes;
